/* GamesApp.css */
.games-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
    font-family: 'Arial', sans-serif;
  }
  
  .games-list {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
  
  .game-button {
    width: 100%;
    background-color: blue;
    color: white;
    border: none;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .create-game-button {
    background-color: green;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  