/* CreateSecretSantaApp.css */

.secret-santa-app {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #e44d26; /* Rojo navideño */
}

.participants-section {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.participant {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.input-container {
  flex: 1;
  margin-right: 20px;
}

.exclusions-container {
  flex: 2;
  margin-bottom: 20px;
}

select {
  width: 100%;
  height: 150px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  outline: none;
}

select:focus {
  border-color: #e44d26;
}

button {
  padding: 15px;
  background-color: #e44d26;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 8px;
  margin-top: 10px;
 
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover {
  background-color: #b83c22; /* Tonos más oscuros para resaltar */
}

/* Toque navideño */
.secret-santa-app::before {
  content: '';
  position: absolute;
  align-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, #e44d26, #f5f5f5 50%);
  z-index: -1;
}

