/* Styles for the AutocompleteInputWithCounter component */
.deckName {
    display: -webkit-inline-box;
    width: 100%; /* Total width of the component */
  }
  
  
  .deckName-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    display: inline-block;
  }
