html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.deck-description-and-input {
  width: 450px;
  gap: 16px;
  position: -webkit-sticky; /* Añade esta línea para compatibilidad con Safari */
  position: sticky;
  top: 20px;
  height: calc(100vh - 40px); /* Añade esta línea */
  overflow-y: auto; /* Añade esta línea */
}

.App {
  display: flex;
  align-items: flex-start;
  height: 100%;
  min-height: 100vh; /* Actualiza esta línea */
  width: 100%;
  font-family: Arial, sans-serif;
  padding-top: 20px;
  background-color: #f0f0f0;
}


.deck-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.deck-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  gap: 16px;
}

.card-groups {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  position: relative;
}

.card-quantity {
  font-size: 3rem;
  opacity: 0.5;
  position: absolute;
  z-index: 1;
  margin-bottom: 20px;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  position: relative;
  z-index: 2;
  min-height: 100px;
}

.card-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.card-image {
  width: 100px;
  height: auto;
}

form {
  flex: 1;
  width: 100%;
  padding: 20px;
  position: sticky;
  top: 10%;
  height: 80%;
  overflow-y: auto;
  left: 0;
}

textarea {
  width: 100%;
  height: 600px;
  resize: none;
}

.deck-image {
  max-width: 100%; /* Asegurarse de que la imagen no sea más ancha que su contenedor */
  height: auto; /* Mantener la proporción de aspecto de la imagen */
}

.selected-card {
  width: 350px;
  margin-top: 10px;
  margin-left: 5px;
}

.selected-card img{
  width: 350px;
}

@media (max-width: 767px) {
  .deck-description-and-input {
    display: none;
  }

  .deck-container {
    width: 100%;
    flex-direction: column;
  }

  .deck-display {
    margin-bottom: 2rem;
  }

  form {
    max-width: 100%;
    margin-bottom: 2rem;
    height: 40vh;
    overflow-y: auto;
  }
}
