/* ShowSecretSantaApp.css */

.show-secret-santa-app {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .message-container {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
  }
  
  h1 {
    color: #007bff;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .festive-icons {
    font-size: 30px;
    margin-top: 20px;
  }
  
  /* Add more styles for a festive look! */
  