/* PlayerApp.css */
.player-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
    font-family: "Arial", sans-serif;
}

.role-info.hidden {
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
}

.error-message {
    text-align: center;
    color: red;
}

.role-info {
    position: relative;
    text-align: center;
    font-family: "FantasyFont", Arial, sans-serif;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 150px;
}

.role-info .content {
    filter: blur(5px); /* Efecto de desenfoque */
}

.role-info.blurred {
    filter: blur(5px); /* Efecto de desenfoque para ocultar el contenido */
}

.show-role-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: black;
}

.players-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.player-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 80px; /* Ajusta según necesidad */
    position: relative;
}

.player-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.player-name {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px; /* Espacio entre nombre y rol */
}

.role-info.visible .content {
    filter: none; /* Remueve el desenfoque cuando es visible */
}

.dead-message {
    text-align: center;
    color: red;
    font-size: 20px;
}

.dead-message svg {
    font-size: 50px;
}

.love-indicator {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.love-indicator svg {
    margin-right: 10px;
}

.player-button.disabled {
    color: #ccc;
    background-color: #f5f5f5;
    border-color: #ccc;
    cursor: not-allowed;
}

.player-name-header {
    text-align: center;
    margin: 10px 0;
}

.player-name-header h1 {
    font-size: 1.5em;
    color: #4a4a4a; /* o cualquier otro color que armonice con tu diseño */
    margin: 0;
    padding: 5px;
    border-bottom: 2px solid #ccc; /* una línea decorativa, opcional */
}

.votes-chart {
    width: 100%;
    margin-top: 20px;
}

.seen-players {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.seen-player-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.eye-icon {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 10px;
}

.player-name,
.player-role {
    font-size: 0.85rem;
    margin: 2px 0;
}

.player-name {
    font-weight: bold;
}

.player-role {
    color: #666;
    background-color: #eef;
    padding: 2px 4px;
    border-radius: 4px;
}

.player-item.voted {
    /* Estilo para jugadores que ya han votado */
    background-color: #d4edda; /* Fondo verde claro */
}

.voting-description {
    margin: 20px 0;
    padding: 15px;
    background-color: #ffffff; /* Fondo blanco para un aspecto limpio */
    border-left: 4px solid #007bff; /* Borde lateral para resaltar */
    text-align: left; /* Alineación del texto a la izquierda para flujo natural */
    color: #333; /* Color de texto oscuro para contraste */
    font-size: 0.95rem; /* Tamaño de fuente ligeramente más pequeño */
    line-height: 1.6; /* Espaciado de línea para mejor legibilidad */
}

.voting-description p {
    margin: 0;
}
