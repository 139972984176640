.deck-images {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.image-wrapper {
  position: relative;
}

.card-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card-image:hover img {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}

.card-quantity {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-weight: bold;
  font-size:4rem; /* Cambia el tamaño de la fuente aquí */
  padding: 4px 8px; /* Ajusta el espacio alrededor del número */
  border-radius: 5px; /* Aumenta el radio del borde */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); /* Añade un efecto de sombra */
}

.card-quantity-total {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  font-size: 6rem; /* Cambia el tamaño de la fuente aquí */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .deck-images {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .image-wrapper {
    position: relative;
    margin-bottom: -40%; /* Ajuste para mostrar solo el 10% de la parte superior de cada carta */
    z-index: 1;
  }

  .image-wrapper.expanded {
    position: static;
    z-index: 1000;
  }
}

