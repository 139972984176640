/* SelectPlayers.css */
.select-players {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
  font-family: 'Arial', sans-serif;
}

.select-players .player-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
}

.select-players .special-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.select-players .master-button {
  background-color: green;
  margin-bottom: 10px;
}

.select-players .back-button {
  background-color: grey;
}

/* Estilos para ambos botones especiales */
.select-players .master-button, .select-players .back-button {
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 80%;
  max-width: 300px;
  border: none;
}

.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.confirmation-dialog p {
  margin-bottom: 20px;
  text-align: center;
}

.confirmation-dialog .accept-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
}

.confirmation-dialog .cancel-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.confirmation-dialog button {
  width: auto;
  min-width: 80px; /* Asegura que los botones no sean demasiado pequeños */
}