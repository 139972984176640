.master-app {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Arial", sans-serif;
}

.player-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.player-list * {
    cursor: pointer;
}

.player-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 80px; /* Ajusta según necesidad */
    position: relative;
}

.player-name {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px; /* Espacio entre nombre y rol */
}

.player-role {
    font-size: 0.8em;
    color: #666;
}

.kill-icon {
    font-size: 40px;
    cursor: pointer;
    margin-top: 20px;
}

.kill-icon-container {
    display: inline-flex; /* O simplemente 'flex' según tus necesidades */
    align-items: center;
    justify-content: center;
    cursor: grab; /* Cambia a 'grabbing' cuando se arrastra */
}

.votes-chart {
    width: 100%;
    margin-top: 20px;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

.checkbox-label {
    margin-left: 5px;
    font-size: 1em;
}

.controls select,
.controls input[type="checkbox"] {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: all 0.2s ease;
    height: auto;
}

.controls button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.controls button:hover {
    background-color: #45a049;
}

.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
    text-align: center;
}

.confirmation-dialog button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.error-message {
    text-align: center;
    color: red;
    margin-bottom: 20px;
}

.player-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Altura total de la ventana */
    background-color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
}

.player-name-large {
    font-size: 3em; /* Ajusta según necesidad */
    font-weight: bold;
    margin-bottom: 0.5em;
}

.player-role-large {
    font-size: 5em;
    font-weight: bold;
    color: #666;
}

.action-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.action-icon {
    font-size: 30px;
    cursor: pointer;
}

.player-icons {
    position: absolute;
    top: 1px;
    right: 5px;
    display: flex;
    gap: 5px;
}

.player-icon {
    font-size: 15px; /* Ajusta el tamaño según necesites */
    color: #666; /* Color discreto */
}

.game-turns {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto; /* Permite desplazarse horizontalmente si hay muchos turnos */
    margin-top: 20px;
    padding: 10px 0;
    border-radius: 10px; /* Bordes redondeados para la barra */
}

.turn-item {
    background-color: #4a90e2;
    border: none;
    border-radius: 10px;
    padding: 3px 9px;
    margin: 0 10px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 10px;
}

.icon-selected {
    color: #4caf50; /* Cambiar el color del icono */
    background-color: rgba(76, 175, 80, 0.1); /* Fondo suave */
    border-radius: 50%; /* Para mantener la forma circular del icono */
    padding: 5px; /* Espacio adicional alrededor del icono */
}

.separator {
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
    color: #666;
    margin-bottom: 10px;
}

.dead-players {
    opacity: 0.6; /* Estilo para jugadores no vivos */
}

.player-item.not-required {
    /* Estilo para jugadores cuya votación no es requerida */
    background-color: #fff; /* Fondo blanco */
}

.player-item.voted {
    /* Estilo para jugadores que ya han votado */
    background-color: #d4edda; /* Fondo verde claro */
}

.player-item.not-voted {
    /* Estilo para jugadores que aún no han votado */
    background-color: #f8d7da; /* Fondo rojo claro */
}
