/* Styles for the AutocompleteInputWithCounter component */
.autocompletewithcounter {
    display: -webkit-inline-box;
    width: 100%; /* Total width of the component */
  }
  
  .counter-input {
    width: 40px; /* Width of the counter input */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    display: inline-block;
    margin-right: 10px; /* Spacing between the counter input and autocomplete input */
    margin-top: 10px;
  }
  
  .autocomplete-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    display: inline-block;
  }

  .add-button {
    flex: 1;
    padding: 10px 15px;
    background-color: #007bff;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    display: inline-block;
    transition: background-color 0.3s; /* Hover effect */
  }
  
  .add-button:hover {
    background-color: #0056b3; /* Change color on hover */
  }
  
  /* Additional styles, such as styles for the suggestions */
  .suggestions {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
  }
  
  .suggestions li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-bottom: 1px solid #ccc;
  }
  
  .suggestions li:last-child {
    border-bottom: none;
  }
  
  .suggestions li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  