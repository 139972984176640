/* Legalities.module.css */
.legalityContainer {
    display: flex;
    justify-content: space-between;
    margin: 8px; /* Ajusta según tu preferencia */
  }
  
  .legalityItem {
    flex: 1;
    text-align: center;
    margin: 0 8px; /* Ajusta según tu preferencia */
  }
  
  .buttonGreen,
  .buttonRed {
    background-color: #4caf50; /* Verde por defecto */
    color: #fff; /* Texto blanco */
    padding: 10px; /* Ajusta según tu preferencia */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%; /* Ajusta según tu preferencia */
    max-width: 150px; /* Ajusta según tu preferencia */
  }
  
  .buttonRed {
    background-color: #f44336; /* Rojo por defecto */
  }
  
  /* Estilos adicionales para hover o foco si lo deseas */
  .buttonGreen:hover,
  .buttonGreen:focus {
    background-color: #45a049; /* Verde más oscuro */
  }
  
  .buttonRed:hover,
  .buttonRed:focus {
    background-color: #e53935; /* Rojo más oscuro */
  }
  