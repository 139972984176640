.deck-display-container {
  overflow-y: auto;
  max-height: 100vh;
}

.deck-display {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
}

.deck-section {
  margin-bottom: 2rem;
}

.deck-section.main-deck .card-image {
  min-width: 200px;
}

.deck-section.sideboard .card-image {
  width: 100px;
}

.card-image {
  height: auto;
  max-width: 100%;
}
