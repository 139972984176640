
textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  font-size: 18px; /* Tamaño de fuente de 20px */
  font-family: 'Arial', sans-serif; /* Fuente Arial o Helvetica */
  line-height: 1.5;
  color: #333;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 1px 1px rgba(0, 0, 0, 0.24);
}

textarea:focus {
  outline: none; /* Quita el contorno predeterminado al enfocar */
  border-color: #007bff; /* Cambia el color del borde al enfocar */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Agrega una sombra al enfocar */
}

@media (max-width: 767px) {
  .deck-input {
    width: 100%;
    height: 40vh;
    position: relative;
    margin-bottom: 2rem;
  }

  .textarea {
    height: 100%;
  }
}
