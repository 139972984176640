.deck-section {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

.deck-section:first-child {
  margin-bottom: 2rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .deck-section {
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: auto;
    padding-bottom: 2rem;
  }

  .deck-section:not(:last-child) {
    margin-bottom: 10rem;
  }
}

/* Estilo básico de las pestañas */
.tabs {
  display: flex;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Estilo de las pestañas cuando están inactivas */
.tab {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.2); /* Fondo similar al de .deck-section */
  color: #333; /* Color de texto similar al de .deck-section */

}

/* Estilo de las pestañas cuando están activas */
.tab.active {
  background-color: #007bff;
  color: #fff;
}

/* Estilo de las pestañas al pasar el cursor sobre ellas */
.tab:hover {
  background-color: #ccc;
  color: #000;
}
