.deckDisplayContainer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px;
    gap: 20px; /* Añade espacio entre las columnas */
  }
  
  .deckDisplay {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .deckSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .cardType, .cardCost {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  }
  
  .cardType p, .cardCost p {
    margin: 0;
    font-size: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .cardImage {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  