/* ShareButton.module.css */
.shareButton {
    background-color: #4a94e0;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: background-color 0.3s, box-shadow 0.3s;
    width: 160px;
    display: inline-block; /* Agregamos esta propiedad */
    margin-right: 10px; /* Puedes ajustar la separación aquí */
  }
  
  .shareButton:hover {
    background-color: #3c7ac9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0 2px 3px rgba(0, 0, 0, 0.3);
  }
  
  .shareButton:focus {
    outline: none;
  }
  