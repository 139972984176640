/* VillageApp.css */
.village-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
    font-family: 'Arial', sans-serif;
  }
  
  .list-name-input, .send-list-button {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .send-list-button {
    background-color: blue;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .player-counter {
    font-size: 24px;
    color: lightblue;
    padding: 10px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .name-list {
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
  }
  
  .name-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e0e0e0;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .name-item button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-list-button.disabled {
    background-color: grey;
    cursor: not-allowed;
  }

  .submission-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .submission-success p {
    font-size: 18px;
    margin-bottom: 20px;
    color: green;
  }
  
  .submission-success button {
    background-color: blue;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }