/* ErrorAlert.module.css */
.errorAlert {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: fixed; /* Añadir esta línea */
    top: 20px; /* Añadir esta línea */
    right: 20px; /* Añadir esta línea */
    z-index: 1000; /* Añadir esta línea */
  }
  