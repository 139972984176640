.generate-hand-button {
    margin: 1rem auto;
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: #4caf50;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .add-one-card {
    margin: 1rem auto;
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: #a8a62d;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 10px;
  }

  
  .generate-hand-button:hover {
    background-color: #45a049;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  }

  .add-one-card:hover {
    background-color: #68671e;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  }
  
  .generate-hand-button:disabled,.add-one-card:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    box-shadow: none;
  }
  
  .random-hand-images {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
  
  .random-hand-images .card-image {
    position: relative;
    margin-left: calc(15% - 7rem);
  }
  
  .random-hand-images .card-image:first-child {
    margin-left: 0;
  }

  .deck-section {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem;
  }
  
  .deck-section:first-child {
    margin-bottom: 2rem;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  
  @media (max-width: 600px) {
    .random-hand-images {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  
    .random-hand-images .card-image:not(:last-child) {
      margin-bottom: -3rem;
    }
  }
 
  @media (max-width: 768px) {
    .deck-section {
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: auto;
      padding-bottom: 2rem;
    }
  
    .random-hand-images {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      margin-bottom: 10rem;
    }
  }
